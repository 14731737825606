var pIE            = require(77)
  , createDesc     = require(85)
  , toIObject      = require(107)
  , toPrimitive    = require(110)
  , has            = require(39)
  , IE8_DOM_DEFINE = require(42)
  , gOPD           = Object.getOwnPropertyDescriptor;

exports.f = require(28) ? gOPD : function getOwnPropertyDescriptor(O, P){
  O = toIObject(O);
  P = toPrimitive(P, true);
  if(IE8_DOM_DEFINE)try {
    return gOPD(O, P);
  } catch(e){ /* empty */ }
  if(has(O, P))return createDesc(!pIE.f.call(O, P), O[P]);
};