'use strict';
var $export         = require(32)
  , toObject        = require(109)
  , aFunction       = require(3)
  , $defineProperty = require(67);

// B.2.2.2 Object.prototype.__defineGetter__(P, getter)
require(28) && $export($export.P + require(69), 'Object', {
  __defineGetter__: function __defineGetter__(P, getter){
    $defineProperty.f(toObject(this), P, {get: aFunction(getter), enumerable: true, configurable: true});
  }
});