'use strict';
var $export         = require(32)
  , toObject        = require(109)
  , aFunction       = require(3)
  , $defineProperty = require(67);

// B.2.2.3 Object.prototype.__defineSetter__(P, setter)
require(28) && $export($export.P + require(69), 'Object', {
  __defineSetter__: function __defineSetter__(P, setter){
    $defineProperty.f(toObject(this), P, {set: aFunction(setter), enumerable: true, configurable: true});
  }
});