'use strict';
var global      = require(38)
  , dP          = require(67)
  , DESCRIPTORS = require(28)
  , SPECIES     = require(117)('species');

module.exports = function(KEY){
  var C = global[KEY];
  if(DESCRIPTORS && C && !C[SPECIES])dP.f(C, SPECIES, {
    configurable: true,
    get: function(){ return this; }
  });
};