// https://github.com/tc39/proposal-object-getownpropertydescriptors
var $export        = require(32)
  , ownKeys        = require(80)
  , toIObject      = require(107)
  , gOPD           = require(70)
  , createProperty = require(24);

$export($export.S, 'Object', {
  getOwnPropertyDescriptors: function getOwnPropertyDescriptors(object){
    var O       = toIObject(object)
      , getDesc = gOPD.f
      , keys    = ownKeys(O)
      , result  = {}
      , i       = 0
      , key;
    while(keys.length > i)createProperty(result, key = keys[i++], getDesc(O, key));
    return result;
  }
});