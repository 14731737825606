'use strict';
var $export                  = require(32)
  , toObject                 = require(109)
  , toPrimitive              = require(110)
  , getPrototypeOf           = require(74)
  , getOwnPropertyDescriptor = require(70).f;

// B.2.2.4 Object.prototype.__lookupGetter__(P)
require(28) && $export($export.P + require(69), 'Object', {
  __lookupGetter__: function __lookupGetter__(P){
    var O = toObject(this)
      , K = toPrimitive(P, true)
      , D;
    do {
      if(D = getOwnPropertyDescriptor(O, K))return D.get;
    } while(O = getPrototypeOf(O));
  }
});