// 26.1.4 Reflect.deleteProperty(target, propertyKey)
var $export  = require(32)
  , gOPD     = require(70).f
  , anObject = require(7);

$export($export.S, 'Reflect', {
  deleteProperty: function deleteProperty(target, propertyKey){
    var desc = gOPD(anObject(target), propertyKey);
    return desc && !desc.configurable ? false : delete target[propertyKey];
  }
});