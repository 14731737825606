'use strict';
// 22.1.3.13 Array.prototype.join(separator)
var $export   = require(32)
  , toIObject = require(107)
  , arrayJoin = [].join;

// fallback for not array-like strings
$export($export.P + $export.F * (require(45) != Object || !require(96)(arrayJoin)), 'Array', {
  join: function join(separator){
    return arrayJoin.call(toIObject(this), separator === undefined ? ',' : separator);
  }
});