const hello = require('../hello.js');

{

	hello.init({

		flickr: {

			name: 'Flickr',

			// Ensure that you define an oauth_proxy
			oauth: {
				version: '1.0a',
				auth: 'https://www.flickr.com/services/oauth/authorize?perms=read',
				request: 'https://www.flickr.com/services/oauth/request_token',
				token: 'https://www.flickr.com/services/oauth/access_token'
			},

			// API base URL
			base: 'https://api.flickr.com/services/rest',

			// Map GET resquests
			get: {
				me: sign('flickr.people.getInfo'),
				'me/friends': sign('flickr.contacts.getList', {per_page: '@{limit|50}'}),
				'me/following': sign('flickr.contacts.getList', {per_page: '@{limit|50}'}),
				'me/followers': sign('flickr.contacts.getList', {per_page: '@{limit|50}'}),
				'me/albums': sign('flickr.photosets.getList', {per_page: '@{limit|50}'}),
				'me/album': sign('flickr.photosets.getPhotos', {photoset_id: '@{id}'}),
				'me/photos': sign('flickr.people.getPhotos', {per_page: '@{limit|50}'})
			},

			wrap: {
				me(o) {
					formatError(o);
					o = checkResponse(o, 'person');
					if (o.id) {
						if (o.realname) {
							o.name = o.realname._content;
							const m = o.name.split(' ');
							o.first_name = m.shift();
							o.last_name = m.join(' ');
						}

						o.thumbnail = getBuddyIcon(o, 'l');
						o.picture = getBuddyIcon(o, 'l');
					}

					return o;
				},

				'me/friends': formatFriends,
				'me/followers': formatFriends,
				'me/following': formatFriends,
				'me/albums'(o) {
					formatError(o);
					o = checkResponse(o, 'photosets');
					paging(o);
					if (o.photoset) {
						o.data = o.photoset;
						o.data.forEach(item => {
							item.name = item.title._content;
							item.photos = `https://api.flickr.com/services/rest${  getApiUrl('flickr.photosets.getPhotos', {photoset_id: item.id}, true)}`;
						});

						delete o.photoset;
					}

					return o;
				},

				'me/photos'(o) {
					formatError(o);
					return formatPhotos(o);
				},

				default(o) {
					formatError(o);
					return formatPhotos(o);
				}
			},

			xhr: false,

			jsonp(p, qs) {
				if (p.method === 'get') {
					delete qs.callback;
					qs.jsoncallback = p.callbackID;
				}
			}
		}
	});

	function getApiUrl(method, extraParams, skipNetwork) {
		let url = `${(skipNetwork) ? '' : 'flickr:'
		}?method=${  method
		}&api_key=${  hello.services.flickr.id
		}&format=json`;
		for (const param in extraParams) {
			if (extraParams.hasOwnProperty(param)) {
				url += `&${  param  }=${  extraParams[param]}`;
			}
		}

		return url;
	}

	// This is not exactly neat but avoid to call
	// The method 'flickr.test.login' for each api call

	function withUser(cb) {
		const auth = hello.getAuthResponse('flickr');
		cb(auth && auth.user_nsid ? auth.user_nsid : null);
	}

	function sign(url, params) {
		if (!params) {
			params = {};
		}

		return function(p, callback) {
			withUser(userId => {
				params.user_id = userId;
				callback(getApiUrl(url, params, true));
			});
		};
	}

	function getBuddyIcon(profile, size) {
		let url = 'https://www.flickr.com/images/buddyicon.gif';
		if (profile.nsid && profile.iconserver && profile.iconfarm) {
			url = `https://farm${  profile.iconfarm  }.staticflickr.com/${
				profile.iconserver  }/` +
				`buddyicons/${  profile.nsid
				}${(size) ? `_${  size}` : ''  }.jpg`;
		}

		return url;
	}

	// See: https://www.flickr.com/services/api/misc.urls.html
	function createPhotoUrl(id, farm, server, secret, size) {
		size = (size) ? `_${  size}` : '';
		return `https://farm${  farm  }.staticflickr.com/${  server  }/${  id  }_${  secret  }${size  }.jpg`;
	}

	function formatError(o) {
		if (o && o.stat && o.stat.toLowerCase() !== 'ok') {
			o.error = {
				code: 'invalid_request',
				message: o.message
			};
		}
	}

	function formatPhotos(o) {
		if (o.photoset || o.photos) {
			const set = ('photoset' in o) ? 'photoset' : 'photos';
			o = checkResponse(o, set);
			paging(o);
			o.data = o.photo;
			delete o.photo;
			for (let i = 0; i < o.data.length; i++) {
				const photo = o.data[i];
				photo.name = photo.title;
				photo.picture = createPhotoUrl(photo.id, photo.farm, photo.server, photo.secret, '');
				photo.pictures = createPictures(photo.id, photo.farm, photo.server, photo.secret);
				photo.source = createPhotoUrl(photo.id, photo.farm, photo.server, photo.secret, 'b');
				photo.thumbnail = createPhotoUrl(photo.id, photo.farm, photo.server, photo.secret, 'm');
			}
		}

		return o;
	}

	// See: https://www.flickr.com/services/api/misc.urls.html
	function createPictures(id, farm, server, secret) {

		const NO_LIMIT = 2048;
		const sizes = [
			{id: 't', max: 100},
			{id: 'm', max: 240},
			{id: 'n', max: 320},
			{id: '', max: 500},
			{id: 'z', max: 640},
			{id: 'c', max: 800},
			{id: 'b', max: 1024},
			{id: 'h', max: 1600},
			{id: 'k', max: 2048},
			{id: 'o', max: NO_LIMIT}
		];

		return sizes.map(size => ({
			source: createPhotoUrl(id, farm, server, secret, size.id),

			// Note: this is a guess that's almost certain to be wrong (unless square source)
			width: size.max,
			height: size.max
		}));
	}

	function checkResponse(o, key) {

		if (key in o) {
			o = o[key];
		}
		else if (!('error' in o)) {
			o.error = {
				code: 'invalid_request',
				message: o.message || 'Failed to get data from Flickr'
			};
		}

		return o;
	}

	function formatFriends(o) {
		formatError(o);
		if (o.contacts) {
			o = checkResponse(o, 'contacts');
			paging(o);
			o.data = o.contact;
			delete o.contact;
			for (let i = 0; i < o.data.length; i++) {
				const item = o.data[i];
				item.id = item.nsid;
				item.name = item.realname || item.username;
				item.thumbnail = getBuddyIcon(item, 'm');
			}
		}

		return o;
	}

	function paging(res) {
		if (res.page && res.pages && res.page !== res.pages) {
			res.paging = {
				next: `?page=${  ++res.page}`
			};
		}
	}

}
